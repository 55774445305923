<template>
  <div>
    <el-row :gutter="20">
      <el-col :span="6">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item>{{ this.direcParam.directName }}</el-breadcrumb-item>
        </el-breadcrumb>
      </el-col>
      <el-col :span="5">
        <el-select v-model="tableParam.selectTask" placeholder="选择任务" style="width:100%;" filterable @change="tableChangeSearchKey">
          <el-option label="全部任务" value=""></el-option>
          <el-option v-for="item in allTask" :key="item.id" :label="item.taskYear+'-'+item.taskName+'-'+item.taskCode" :value="item.id"></el-option>
        </el-select>
      </el-col>
      <el-col :span="2">
        <el-select v-model="tableParam.selectDepart" placeholder="选择部门" style="width:100%;" filterable @change="tableChangeSearchKey"
          v-if="this.userInfo.depName=='全部部门'">
          <el-option label="全部部门" value=""></el-option>
          <el-option v-for="item in allDepart" :key="item.depart" :label="item.depart" :value="item.depart"></el-option>
        </el-select>
      </el-col>
      <el-col :span="2">
        <el-select v-model="tableParam.selectBelong" placeholder="选择责任人" style="width:100%;" filterable @change="tableChangeSearchKey">
          <el-option label="全部责任人" value=""></el-option>
          <el-option v-for=" item in allBelong" :key="item.belongMan" :label="item.belongMan" :value="item.belongMan"></el-option>
        </el-select>
      </el-col>
      <el-col :span="2">
        <el-select v-model="tableParam.checkState" placeholder="选择检测结果" style="width:100%;" filterable @change="tableChangeSearchKey">
          <el-option label="检测结果" :value="-1"></el-option>
          <el-option label="通过" :value="1"></el-option>
          <el-option label="未通过" :value="0"></el-option>
        </el-select>
      </el-col>
      <el-col :span="2">
        <el-select v-model="tableParam.manResult" placeholder="选择处理结果" style="width:100%;" filterable @change="tableChangeSearchKey">
          <el-option label="审核结果" :value="-1"></el-option>
          <el-option label="通过" :value="1"></el-option>
          <el-option label="未通过" :value="0"></el-option>
        </el-select>
      </el-col>
      <el-col :span="5">
        <div>
          <el-input placeholder="请输入搜索内容" v-model.trim="tableParam.key" style="width:100%" @change="tableChangeSearchKey">
            <el-button slot="append" icon="el-icon-search"></el-button>
          </el-input>
        </div>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24">
        <el-button-group>
          <el-button @click="formDataReCheck" type="primary">重新检查</el-button>
          <el-button @click="outReport" type="success" title="按当前条件生成全部报告">按当前过滤条件生成检测报告</el-button>
          <el-button @click="formDataDelete" type="danger">删除</el-button>
        </el-button-group>
      </el-col>
    </el-row>
    <el-table ref="checkHistoryTableRef" :key="tableParam.tableKey" :border="true" height="666" :cell-style="{height:'44px',padding:0,margin:0}"
      :data="tableData" tooltip-effect="dark" style="width: 100%" :highlight-current-row="true" @selection-change="tableSelectionChange"
      @row-click="tableRowClick">
      <el-table-column header-align="center" type="selection" width="40"></el-table-column>
      <el-table-column type="expand">
        <template slot-scope="scope">
          <div style="padding-left:10px;padding-right:10px">
            <table class="checkInfoClass">
              <thead>
                <th colspan="4">检查内容</th>
                <th style="width:6%">检出状态</th>
                <th style="width:15%">检出描述</th>
              </thead>
              <tr>
                <th>设备基础信息</th>
                <td colspan="3">
                  设备编号:{{scope.row.deviceCode}}&nbsp;&nbsp;&nbsp;
                  设备类型:{{scope.row.deviceType}}&nbsp;&nbsp;&nbsp;
                  规格型号:{{scope.row.deviceModel}}&nbsp;&nbsp;&nbsp;
                  硬盘物理号:{{scope.row.diskSn}}&nbsp;&nbsp;&nbsp;
                  联网状态:{{scope.row.linkState}}&nbsp;&nbsp;&nbsp;
                  使用状态:{{scope.row.useState}}&nbsp;&nbsp;&nbsp;
                  启用日期:{{scope.row.startDate}}&nbsp;&nbsp;&nbsp;
                  用途:{{scope.row.useful}}&nbsp;&nbsp;&nbsp;
                </td>
              </tr>
              <tr>
                <th style="width:120px;">操作系统</th>
                <td>{{scope.row.operatingSystem}}</td>
                <th style="width:120px;">检出操作系统</th>
                <td>{{scope.row.checkOperatingSystem}}</td>
                <td align="center"><el-tag size="mini" type="primary"
                    v-if="scope.row.checkOperatingSystem.toUpperCase().indexOf(scope.row.operatingSystem.toUpperCase())>0">相符</el-tag>
                  <el-tag size="mini" type="danger" v-else>不相符</el-tag>
                </td>
                <td></td>
              </tr>
              <tr>
                <th>系统安装时间</th>
                <td>{{scope.row.installTime}}</td>
                <th>检出安装时间</th>
                <td>{{scope.row.checkInstallTime}}</td>
                <td align="center"><el-tag size="mini" type="primary" v-if="scope.row.installTime==scope.row.checkInstallTime">相符</el-tag>
                  <el-tag size="mini" type="danger" v-else>不相符</el-tag>
                </td>
                <td></td>
              </tr>
              <tr>
                <th>MAC地址</th>
                <td>{{scope.row.macAddress}}</td>
                <th>检出MAC地址</th>
                <td>
                  <div v-for="item in scope.row.checkMacAddress" :key="'mac'+item.mac">
                    <span v-if="item.meet" style="background-color:#cccccc;">{{ item.mac}}</span>
                    <span v-else>{{ item.mac}}</span>
                  </div>
                </td>
                <td align="center"><el-tag size="mini" type="primary" v-if="checkMeet(scope.row.checkMacAddress,scope.row.macAddress)">已命中</el-tag>
                  <el-tag size="mini" type="danger" v-else>未命中</el-tag>
                </td>
                <td></td>
              </tr>
              <tr>
                <th>IP地址</th>
                <td>{{scope.row.ipAddress}}</td>
                <th>检出IP地址</th>
                <td>
                  <div v-for="item in scope.row.checkIpAddress" :key="'IpAddress'+item.ip">
                    <span v-if="item.meet" style="background-color:#cccccc;">{{ item.ip}}</span>
                    <span v-else>{{ item.ip}}</span>
                  </div>
                </td>
                <td align="center"> <el-tag size="mini" type="primary" v-if="checkMeet(scope.row.checkIpAddress,scope.row.ipAddress)">已命中</el-tag>
                  <el-tag size="mini" type="danger" v-else>未命中</el-tag>
                </td>
                <td></td>
              </tr>
              <tr>
                <th>硬盘序列号</th>
                <td>{{scope.row.diskSn}}</td>
                <th>检出硬盘序列号</th>
                <td>
                  <div v-for="item in scope.row.checkStorage" :key="'diskSn'+item.serialNumber">
                    <span v-if="item.meet" style="background-color:#cccccc;">{{ item.serialNumber}}</span>
                    <span v-else>{{ item.serialNumber}}</span>
                  </div>
                </td>
                <td align="center"> <el-tag size="mini" type="primary" v-if="checkInfoValidate(scope.row.checkStorage)">已命中</el-tag>
                  <el-tag size="mini" type="danger" v-else>未命中</el-tag>
                </td>
                <td></td>
              </tr>
            </table>
            <table class="checkInfoClass" style="margin-top:5px ;">
              <tr v-for="(item,index) in scope.row.checkStorage" :key="item.serialNumber">
                <th style="width:120px;">检出存储-{{ index+1 }} </th>
                <td>{{item.serialNumber}}</td>
                <th>密级</th>
                <td>{{item.secretLevel }}</td>
                <th>设备类型</th>
                <td>{{item.deviceType }}</td>
                <th>规格型号</th>
                <td>{{item.deviceModel }}</td>
                <!-- <th>所属部门</th>
                <td>{{item.depart }}</td>
                <th>责任人</th> 
                <td>{{item.belongMan }}</td>-->
                <th>设备编号</th>
                <td>{{item.deviceCode}}</td>
                <th>使用状态</th>
                <td>{{item.useState }}</td>
                <td style="width:6%;" align="center">
                  <el-tag size="mini" type="primary" v-if="item.checkStatus">{{item.checkTitle}}</el-tag>
                  <el-tag size="mini" type="danger" v-else-if="item.checkTitle=='严重违规'">{{item.checkTitle}}</el-tag>
                  <el-tag size="mini" type="warning" v-else>{{item.checkTitle}}</el-tag>
                </td>
                <td style="width:15%;">{{item.checkInfo}}
                </td>
              </tr>
              <tr v-for="(item,index) in scope.row.checkExtDevice" :key="item.serialNumber+'_'+index">
                <th style="width:120px;">检出外设-{{ index+1 }} </th>
                <td>{{item.serialNumber}}</td>
                <th>密级</th>
                <td>{{item.secretLevel }}</td>
                <th>设备类型</th>
                <td>{{item.deviceType }}</td>
                <th>规格型号</th>
                <td>{{item.deviceModel }}</td>
                <!-- <th>所属部门</th>
                <td>{{item.depart }}</td>
                <th>责任人</th> 
                <td>{{item.belongMan }}</td>-->
                <th>设备编号</th>
                <td>{{item.deviceCode}}</td>
                <th>使用状态</th>
                <td>{{item.useState }}</td>
                <td align="center">
                  <el-tag size="mini" type="primary" v-if="item.checkStatus">{{item.checkTitle}}</el-tag>
                  <el-tag size="mini" type="danger" v-else>{{item.checkTitle}}</el-tag>
                </td>
                <td>{{item.checkInfo}}
                </td>
              </tr>
              <tr v-for="(item,index) in scope.row.checkSoft" :key="'checkSoft_'+index">
                <th style="width:120px;">检出软件-{{ index+1 }} </th>
                <td colspan="3">{{item.name}}</td>
                <th style="width:60px;">描述</th>
                <td colspan="7">{{item.descript }}</td>
                <td align="center">
                  <el-tag size="mini" type="primary" v-if="item.result=='true'">正常</el-tag>
                  <el-tag size="mini" type="danger" v-else>违规</el-tag>
                  &nbsp;&nbsp;
                  <el-button @click="softwareInsert(item)" type="danger" size="mini" style="padding:3px" title="加入软件白名单"
                    icon="el-icon-edit-outline"></el-button>
                </td>
                <td>{{item.resultMsg}}
                </td>
              </tr>
              <tr v-for="(item,index) in scope.row.checkProcess" :key="'checkProcess_'+index">
                <th style="width:120px;">检出启动进程-{{ index+1 }} </th>
                <td colspan="3">{{item.name}}</td>
                <th style="width:60px;">描述</th>
                <td colspan="7">{{item.descript }}</td>
                <td align="center">
                  <el-tag size="mini" type="primary" v-if="item.result=='true'">正常</el-tag>
                  <el-tag size="mini" type="danger" v-else>违规</el-tag>
                  &nbsp;&nbsp;
                  <el-button @click="startListDataInsert(item)" type="danger" size="mini" style="padding:3px" title="加入进程白名单"
                    icon="el-icon-edit-outline"></el-button>
                </td>
                <td>{{item.resultMsg}}
                </td>
              </tr>
              <tr v-for="(item,index) in scope.row.checkPower" :key="'checkPower_'+index">
                <th style="width:120px;">开关机-{{ index+1 }} </th>
                <td colspan="3">{{item.name}}</td>
                <th style="width:60px;">时间</th>
                <td colspan="7">{{item.descDate }}</td>
                <td align="center">
                  <el-tag size="mini" type="primary" v-if="item.result=='true'">正常</el-tag>
                  <el-tag size="mini" type="danger" v-else>违规</el-tag>
                </td>
                <td>{{item.resultMsg}}
                </td>
              </tr>
            </table>
          </div>
        </template>
      </el-table-column>
      <el-table-column header-align="center" type="index" width="40"></el-table-column>
      <el-table-column header-align="center" prop="taskInfo" label="所属巡检任务" width="350">
        <template slot-scope="scope">
          <span v-if="scope.row.taskId" v-html="tableLightByKey(scope.row.taskInfo)"></span>
          <span v-else>日常检测</span>
        </template>
      </el-table-column>
      <el-table-column header-align="center" prop="deviceCode" label="设备编号" width="200">
        <template slot-scope="scope">
          <span v-html="tableLightByKey(scope.row.deviceCode)"></span>
        </template>
      </el-table-column>
      <el-table-column header-align="center" prop="secretLevel" label="密级" width="60">
        <template slot-scope="scope">
          <span v-html="tableLightByKey(scope.row.secretLevel)"></span>
        </template>
      </el-table-column>
      <el-table-column header-align="center" prop="depart" label="所属部门" show-overflow-tooltip>
        <template slot-scope="scope">
          <span v-html="tableLightByKey(scope.row.depart)"></span>
        </template>
      </el-table-column>
      <el-table-column header-align="center" prop="belongMan" label="责任人" width="100">
        <template slot-scope="scope">
          <span v-html="tableLightByKey(scope.row.belongMan)"></span>
        </template>
      </el-table-column>
      <el-table-column header-align="center" prop="placeAddress" label="存放地点" show-overflow-tooltip>
        <template slot-scope="scope">
          <span v-html="tableLightByKey(scope.row.placeAddress)"></span>
        </template>
      </el-table-column>
      <el-table-column header-align="center" prop="checkDate" label="检测日期" width="120"></el-table-column>
      <el-table-column header-align="center" align="center" prop="checkState" label="检测结果" width="100">
        <template slot-scope="scope">
          <el-tag size="mini" v-if="scope.row.checkState" effect="dark">通过</el-tag>
          <el-button v-else effect="dark" type='danger' title="点击查看检测报告" size="mini" @click="showReport(scope.row)">未通过</el-button>
        </template>
      </el-table-column>

      <el-table-column header-align="center" align="center" prop="manResult" label="审核结果" width="100">
        <template slot-scope="scope">
          <el-tag size="mini" v-if="scope.row.manResult" effect="dark">通过</el-tag>
          <el-button v-else effect="dark" type='danger' title="点击查看检测报告" size="mini" @click="showReport(scope.row)">未通过</el-button>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="152">
        <template slot-scope="scope">
          <span style="padding-left:10px;"><el-button @click="formDataModify(scope.row)" type="primary" size="small">人工审核</el-button></span>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination @size-change="tableSizeChange" @current-change="tableCurrentChange" :current-page="tableParam.page"
      :page-sizes="[30,40, 50, 60,70,80,90,100]" :page-size="tableParam.limit" layout="total, sizes, prev, pager, next, jumper" :total="tableParam.count">
    </el-pagination>

    <!--编辑的表单-->

    <el-dialog v-dialogDrag class="customFileListCss" :title="formDialogParam.title" :visible.sync="formDialogParam.visible" :width="formDialogParam.width"
      :top="formDialogParam.top" :close-on-click-modal='false'>
      <el-form ref="checkHistoryFormRef" :model="formData" :rules="formDialogRules" label-width="80px" size="small"><!-- `prop` 为绑定的规则 -->
        <el-form-item label="任务信息" prop="taskInfo">
          <el-input v-model="formData.taskInfo" readonly="true"></el-input>
        </el-form-item>
        <el-form-item label="检测结果">
          <el-tag size="mini" v-if="formData.checkState" effect="dark">通过</el-tag>
          <el-tag size="mini" v-else effect="dark" type='danger'>未通过</el-tag>
        </el-form-item>
        <el-form-item label="检测报告" prop="checkStateInfo">
          <el-input v-model="formData.checkStateInfo" :autosize="{ minRows: 10, maxRows: 20}" type="textarea" resize="none" placeholder="请输入检测结果"
            readonly="true"></el-input>
        </el-form-item>
        <el-form-item label="审核结果" prop="manResult">
          <el-radio v-model="formData.manResult" :label="true" border><span>通&nbsp;&nbsp;过</span></el-radio>
          <el-radio v-model="formData.manResult" :label="false" border><span style="color: #F56C6C;">不通过</span></el-radio>
        </el-form-item>
        <el-form-item label="审核意见" prop="manInfo">
          <el-input v-model="formData.manInfo" type="textarea" :rows="3" resize="none" placeholder="请输入处理结论"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="formDialogParam.visible = false">取 消</el-button>
        <el-button type="primary" @click="formDataSubmit">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog v-dialogDrag :title="formChooseDialogParam.title" :visible.sync="formChooseDialogParam.visible" width="50%" :close-on-click-modal='false'>
      <el-table :data="tableMultipleSelection" style="width: 100%;" height="300">
        <el-table-column header-align="center" type="index" width="40"></el-table-column>
        <el-table-column header-align="center" prop="taskInfo" label="所属巡检任务">
          <template slot-scope="scope">
            <span v-if="scope.row.taskId" v-html="tableLightByKey(scope.row.taskInfo)"></span>
            <span>日常检测</span>
          </template>
        </el-table-column>
        <el-table-column header-align="center" prop="deviceCode" label="设备编号">
          <template slot-scope="scope">
            <span v-html="tableLightByKey(scope.row.deviceCode)"></span>
          </template>
        </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button @click="formChooseDialogParam.visible = false">取 消</el-button>
        <el-button v-if="formChooseDialogParam.action1" type="danger" @click="formChooseSubmit(false)">{{formChooseDialogParam.action1View}}</el-button>
        <el-button v-if="formChooseDialogParam.action2" type="primary" @click="formChooseSubmit(true)">{{formChooseDialogParam.action2View}}</el-button>
      </span>
    </el-dialog>
    <el-dialog v-dialogDrag :title="report.title+'检测报告'" :visible.sync="report.visible" width="70%" :close-on-click-modal='false'>
      <div style="padding:20px;line-height:30px;">
        <div><span style="font-weight: bold;">检测结果:</span>
          <span v-if="report.checkState" style="color:#409EFF">通过</span>
          <span v-else style="color:#F56C6C">未通过</span>
        </div>
        <span style="font-weight: bold;">检测报告:</span>
        <div style="padding-left:20px;" v-html="report.checkStateInfo"></div>
        <div><span style="font-weight: bold;">审核结果:</span>
          <span v-if="report.manResult" style="color:#409EFF">通过</span>
          <span v-else style="color:#F56C6C">未通过</span>
        </div>
        <div><span style="font-weight: bold;">审核意见:</span>{{ report.manInfo }}</div>
      </div>
    </el-dialog>
    <el-dialog v-dialogDrag :title="softWareParam.title" :visible.sync="softWareParam.visible" :width="softWareParam.width" :top="softWareParam.top"
      :close-on-click-modal='false'>
      <el-form ref="softwareFormRef" :model="softwareData" :rules="softwareDataRules" label-width="100px" size="small"><!-- `prop` 为绑定的规则 -->
        <el-row :gutter="20" style="padding:0px;">
          <el-col :span="14">
            <el-form-item label="软件名称" prop="softName">
              <el-input id="softName" v-model="softwareData.softName" placeholder="请输入软件名称"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label="要求">
              <el-row :gutter="0" style="padding:0px;">
                <el-col :span="12"><el-checkbox v-model="softwareData.isMust">内网必装</el-checkbox></el-col>
                <el-col :span="12"><el-checkbox v-model="softwareData.isOutMust">单机必装</el-checkbox></el-col>
              </el-row>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20" style="padding:0px;">
          <el-col :span="14">
            <el-form-item label="软件路径" prop="softPath">
              <el-input id="softPath" v-model="softwareData.softPath" placeholder="请输入软件路径"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label="锁定">
              <el-checkbox v-model="softwareData.isLockPath">安装路径</el-checkbox>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="是否要求">
          <el-checkbox v-model="softwareData.isUpdate">检查更新</el-checkbox>
        </el-form-item>
        <el-row :gutter="20" style="padding:0px;" v-if="softwareData.isUpdate">
          <el-col :span="12">
            <el-form-item label="检查的文件" prop="checkFileName">
              <el-input id="checkFileName" v-model="softwareData.checkFileName" placeholder="请输入该目录下检查的文件名称"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="要求的日期" prop="reqUpdateDate">
              <el-date-picker id="reqUpdateDate" v-model="softwareData.reqUpdateDate" value-format="yyyy-MM-dd" format="yyyy-MM-dd" type="date"
                placeholder="请选择要求更新日期"></el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="用途" prop="useful">
          <el-input id="useful" v-model="softwareData.useful" placeholder="请输入用途"></el-input>
        </el-form-item>
        <el-form-item label="排序" prop="sort">
          <el-input-number id="sort" v-model="softwareData.sort" controls-position="right" :precision="2" :step="0.1" :min="0" :max="2147483647.00"
            placeholder="请输入排序"></el-input-number>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="softWareParam.visible = false">取 消</el-button>
        <el-button type="primary" @click="softwareDataSubmit">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog v-dialogDrag :title="startListDataParam.title" :visible.sync="startListDataParam.visible" :width="startListDataParam.width"
      :top="startListDataParam.top" :close-on-click-modal='false'>
      <el-form ref="startListFormRef" :model="startListData" :rules="startListDataRules" label-width="80px" size="small"><!-- `prop` 为绑定的规则 -->
        <el-form-item label="启动文件" prop="startPath">
          <el-input id="startPath" v-model="startListData.startPath" placeholder="请输入启动文件"></el-input>
        </el-form-item>
        <el-form-item label="中文描述" prop="cnDescription">
          <el-input id="cnDescription" v-model="startListData.cnDescription" placeholder="请输入中文描述"></el-input>
        </el-form-item>
        <el-form-item label="用途" prop="useful">
          <el-input id="useful" v-model="startListData.useful" type="textarea" :rows="3" resize="none" placeholder="请输入用途"></el-input>
        </el-form-item>
        <el-form-item label="排序" prop="sort">
          <el-input-number id="sort" v-model="startListData.sort" controls-position="right" :precision="2" :step="0.1" :min="0" :max="2147483647.00"
            placeholder="请输入排序"></el-input-number>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="startListDataParam.visible = false">取 消</el-button>
        <el-button type="primary" @click="startListDataSubmit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<style lang="less" scoped>
@import "@/assets/css/TableCus.css";
</style> 
<style lang="less" scoped>
table.checkInfoClass {
  font-family: verdana, arial, sans-serif;
  font-size: 12px;
  border-width: 1px;
  border-color: #ebeef5;
  border-collapse: collapse;
  width: 100%;
}
table.checkInfoClass thead th,
table.checkInfoClass td,
table.checkInfoClass th {
  height: 24px;
  font-size: 12px;
  line-height: 14px;
  padding: 5px;
  border-width: 1px;
  border-style: solid;
  border-color: #ebeef5;
}
table.checkInfoClass thead th {
  color: #ffffff;
  text-align: center;
  background-color: #666666;
}
table.checkInfoClass td {
  color: #606266;

  background-color: #ffffff;
}
table.checkInfoClass th {
  color: #909399;
  text-align: right;
  border-style: solid;
  border-color: #ebeef5;
  background-color: #fafafa;
}
</style>
<script>
import saveAs from 'file-saver'
import { getTableData, doNew, doModify, doDelete, doReCheck } from '@/api/wbkj/CheckHistory'; //数据源,如果没有上传文件的部分,请删除postFile, postImg,不删除也不影响
import { doNew as doSoftWareNew } from '@/api/wbkj/Software'; //数据源,如果没有上传文件的部分,请删除postFile, postImg,不删除也不影响
import { doNew as doStartListNew } from '@/api/wbkj/StartList'; //数据源,如果没有上传文件的部分,请删除postFile, postImg,不删除也不影响

import { getAllBelong, getAllDepart } from '@/api/wbkj/Host'
import { getAllTask } from '@/api/wbkj/CheckTask'
import { required } from "@/utils/formRules" //校验规则,如有特殊要求请自己去修改填写,请删除不使用的,但不删除也不影响
export default {
  data () {
    return {
      /**startList */
      startListDataParam: //form弹出窗口的显示状态
      {
        visible: false,
        title: '编辑',
        startListDataAction: '',
        width: '50%',
        top: '5vh',
      },
      startListData: {
        startPath: '',
        cnDescription: '',
        useful: '',
        sort: 0.00,

      },
      startListDataRules:
      {
        startPath: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
      },
      /*softWare */
      softWareParam: //softWare弹出窗口的显示状态
      {
        visible: false,
        title: '编辑',
        width: '50%',
        top: '5vh',
      },
      softwareData: {
        softName: '',
        softPath: '',
        isLockPath: false,
        isMust: false,
        isOutMust: false,
        isUpdate: false,
        checkFileName: '',
        reqUpdateDate: new Date(),
        useful: '',
      },
      softwareDataRules:
      {
        softName: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        checkFileName: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        reqUpdateDate: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增

      },
      /* */
      /*导航栏状态 */
      direcParam: {
        'directName': '检测记录'
      },
      userInfo: {},
      report: {
        title: '',
        visible: false,
        checkState: false,
        checkStateInfo: '',
        manResult: false,
        manInfo: '',
      },
      allBelong: [],
      allDepart: [],
      allTask: [],
      /*表格参数 */
      tableParam: {
        'page': 1,
        'limit': 40,
        'count': 0,
        'key': '',
        'searchType': 'normal',
        'selectTask': '',
        'selectDepart': '',
        'selectBelong': '',
        'checkState': -1,
        'manResult': -1,

      },//表格的传参
      /*表格数据 */
      tableData: [],
      /*表格选中的数据 */
      tableMultipleSelection: [], //多选的数据
      /*表单的参数 */
      formDialogParam: //form弹出窗口的显示状态
      {
        visible: false,
        title: '编辑',
        formDataAction: '',
        width: '60%',
        top: '5vh',
      },
      formChooseDialogParam: //form发布弹出窗口的显示状态
      {
        formChooseAction: '',
        title: '',
        visible: false,
        action1: false,
        action1View: '',
        action2: false,
        action2View: ''
      },
      /*原始数据 */
      formRawData: {}, //由于不能直接清空所有的数据回到初使状态(表格参数据库和搜索数据不能清空),所以建立,在mount的时候作一个深拷贝,将formData的数据初使化的原始数据复制一份过来
      /*初使化数据 */
      formData: {
        id: '',
        checkState: false,
        checkStateInfo: '',
        isNeedMan: false,
        manResult: false,
        manInfo: '',

      },
      formDialogRules:
      {
        checkState: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        checkStateInfo: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        isNeedMan: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        manResult: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        manInfo: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增

        // title: [required,len(0,255)],多规则绑定
        // mail: [required,len(0,255), email],多规则绑定
        // authorWriter: [required],
      },
    }
  },
  methods: {
    /*表单(form)-修改 */
    startListDataInsert (row) {
      this.startListData.startPath = row.name;
      this.startListData.cnDescription = row.descript;
      this.startListDataParam.title = '插入到启动白名单';
      this.startListDataParam.visible = true;

    },
    startListDataValidate () {
      let result = true;
      this.$refs.startListFormRef.validate((valid) => {
        result = valid;
      });
      return result;
    },

    startListDataSubmit () {
      if (!this.startListDataValidate()) return;
      this.startListDataParam.visible = false;
      const postData = JSON.parse(JSON.stringify(this.startListData));
      doStartListNew(postData).then(response => {
        if (response.data.success) {
          this.notifySuccess("插入到软件白名单");
        }
        else {
          this.notifyWarning(response.data.msg);
        }
      })
    },

    softwareInsert (row) {
      //this.softwareData = JSON.parse(JSON.stringify(row)); //深COPY主要是为了不想改了,就渲染到table上造成不必要的麻烦;
      this.softwareData.softName = row.name;
      this.softwareData.softPath = row.descript;
      this.softWareParam.title = '插入到软件白名单';
      this.softWareParam.visible = true;
    },
    softwareDataValidate (row) {
      let result = true;
      this.$refs.softwareFormRef.validate((valid) => {
        result = valid;
      });
      return result;
    },
    softwareDataValidate () {
      let result = true;
      this.$refs.softwareFormRef.validate((valid) => {
        result = valid;
      });
      return result;
    },
    softwareDataSubmit () {
      if (!this.softwareDataValidate()) return;
      this.softWareParam.visible = false;
      //如有特殊的Json要求,请深Copy一份,再进行修改例如
      const postData = JSON.parse(JSON.stringify(this.softwareData));
      doSoftWareNew(postData).then(response => {
        if (response.data.success) {
          this.notifySuccess('插入或更新成功,如需得到最新结果,请重新检查');
        }
        else {
          this.notifyWarning(response.data.msg);
        }
      })
    },
    checkMeet (source, indexObject) {
      let str = JSON.stringify(source);
      if (str.indexOf(indexObject) != -1) {
        return true;
      }
      else { return false; }
    },
    checkInfoValidate (checkInfoArray) {
      for (let key in checkInfoArray) {
        console.log(checkInfoArray[key]);
        if (checkInfoArray[key].meet) {
          return true;
        }
      }
      return false;

    },
    showReport (data) {
      this.report.title = data.deviceCode;
      this.report.checkState = data.checkState;
      this.report.checkStateInfo = data.checkStateInfo.replace(/\n/g, '<br>');
      console.log(this.report.checkStateInfo);
      this.report.manResult = data.manResult;
      this.report.manInfo = data.manInfo;
      this.report.visible = true;

    },
    outReport () {  //小文件导出可以这样写,大文件,需要用到filesaver
      const queryString = Object.keys(this.tableParam)
        .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(this.tableParam[key]))
        .join('&');
      saveAs('/wbkj/checkHistory/outReport?downToken=' + this.$store.getters.getToken + '&' + queryString, '检测报告.pdf');
    },

    /*-------------------------------------------------表单(form)事件 -----------------------------------------------*/
    /*表单(form)-新建 */
    formDataNew () {
      this.formData = JSON.parse(JSON.stringify(this.formRawData)); //深拷贝一份原始数据到formData,就相当于重置表单了
      this.formDialogParam.title = '新建';
      this.formDialogParam.formDataAction = 'new';
      this.formDialogParam.visible = true;
    },
    /*表单(form)-修改 */
    formDataModify (row) {
      this.formData = JSON.parse(JSON.stringify(row)); //深COPY主要是为了不想改了,就渲染到table上造成不必要的麻烦;
      this.formDialogParam.formDataAction = 'modify';
      this.formDialogParam.title = '审核';
      this.formDialogParam.visible = true;
    },
    /*表单(form)-删除 */
    formDataDelete () {
      if (this.tableMultipleSelection.length < 1) {
        this.notifyInfo("您必须最少选择一条记录,才能删除");
        return;
      }
      else {
        this.formChooseDialogParam.title = "是否真的要删除选定的记录?"
        this.formChooseDialogParam.formChooseAction = 'delete';
        this.formChooseDialogParam.action1 = true;
        this.formChooseDialogParam.action1View = '删 除';
        this.formChooseDialogParam.action2 = false;
        this.formChooseDialogParam.action2View = '';
        this.formChooseDialogParam.visible = true;
      }
    },
    formDataReCheck () {
      if (this.tableMultipleSelection.length < 1) {
        this.notifyInfo("您必须最少选择一条记录,才能重新检查");
        return;
      }
      else {
        this.formChooseDialogParam.title = "是否真的要重新检查选定的记录?"
        this.formChooseDialogParam.formChooseAction = 'reCheck';
        this.formChooseDialogParam.action1 = true;
        this.formChooseDialogParam.action1View = '重新检查';
        this.formChooseDialogParam.action2 = false;
        this.formChooseDialogParam.action2View = '';
        this.formChooseDialogParam.visible = true;
      }
    },

    formDataValidate () {
      let result = true;
      this.$refs.checkHistoryFormRef.validate((valid) => {
        result = valid;
      });
      return result;
    },
    /*表单(form)-提交 */
    formDataSubmit () {
      if (!this.formDataValidate()) return;
      this.formDialogParam.visible = false;
      //如有特殊的Json要求,请深Copy一份,再进行修改例如
      const postData = JSON.parse(JSON.stringify(this.formData));
      postData.checkIpAddress = JSON.stringify(this.formData.checkIpAddress);//由于MysqlJson的原因
      postData.checkMacAddress = JSON.stringify(this.formData.checkMacAddress);//由于MysqlJson的原因
      postData.checkStorage = JSON.stringify(this.formData.checkStorage);//由于MysqlJson的原因
      postData.checkExtDevice = JSON.stringify(this.formData.checkExtDevice);//由于MysqlJson的原因

      //postData.classId = this.tableParam.classId;
      //postData.imageUrl = JSON.stringify(this.formData.imageUrl);//由于MysqlJson的原因
      //postData.addFile = JSON.stringify(this.formData.addFile);//由于MysqlJson的原因
      //doNew(this.formData).then(response => {
      if (this.formDialogParam.formDataAction == 'new') {
        doNew(postData).then(response => {
          if (response.data.success) {
            this.notifySuccess(response.data.msg);
            this.tableDateRefresh();
          }
          else {
            this.notifyWarning(response.data.msg);
          }
        })
      }
      else if (this.formDialogParam.formDataAction == 'modify') {
        doModify(postData).then(response => {
          if (response.data.success) {
            this.notifySuccess(response.data.msg);
            this.tableDateRefresh();
          }
          else {
            this.notifyWarning(response.data.msg);
            this.tableDateRefresh();
          }
        })
      }
    },
    formChooseSubmit () {
      this.formChooseDialogParam.visible = false;
      if (this.formChooseDialogParam.formChooseAction == 'delete') {
        const selectNum = this.tableMultipleSelection.length;
        let canId = [];
        for (let i = 0; i < selectNum; i++) {
          if (!this.tableMultipleSelection[i].isPublic) {
            canId.push(this.tableMultipleSelection[i].id);
          }
        }
        const data = new FormData()
        data.append('id', canId);
        doDelete(data).then(response => {
          if (response.data.success) {
            this.tableParam.key = '';
            this.tableParam.page = 1;
            this.notifySuccess(response.data.msg);
            this.tableDateRefresh();
          }
          else {
            this.notifyWarning(response.data.msg);
            this.tableDateRefresh();
          }
        })
      }
      else if (this.formChooseDialogParam.formChooseAction == 'reCheck') {
        const selectNum = this.tableMultipleSelection.length;
        let canId = [];
        for (let i = 0; i < selectNum; i++) {
          if (!this.tableMultipleSelection[i].isPublic) {
            canId.push(this.tableMultipleSelection[i].id);
          }
        }
        const data = new FormData()
        data.append('id', canId);
        doReCheck(data).then(response => {
          if (response.data.success) {
            this.notifySuccess(response.data.msg);
            this.tableDateRefresh();
          }
          else {
            this.notifyWarning(response.data.msg);
            this.tableDateRefresh();
          }
        })
      }
    },
    /*-------------------------------------------------表格事件 -----------------------------------------------*/
    //表格-多选事件,当checkBox被选中,改变已选的值
    tableSelectionChange (val) {
      this.tableMultipleSelection = val;
    }
    ,
    /*表格-行选,点击事件 */
    tableRowClick (row) {
      //this.formData = row;
      this.formData = JSON.parse(JSON.stringify(row));//主要是为了不想改了就生效到table上
      console.log(row)
      this.$refs.checkHistoryTableRef.clearSelection(); //清除选中
      this.$refs.checkHistoryTableRef.toggleRowSelection(row); //设定选中
    },
    /*表格-改变每页显示多少条,直接重置到第一页*/
    tableSizeChange (val) {
      if (val != this.tableParam.limit) {
        this.tableParam.limit = val
        this.tableParam.page = 1;
        this.tableDateRefresh();
      }
    },
    /*表格-改变第几页*/
    tableCurrentChange (val) {
      if (val != this.tableParam.page) {
        this.tableParam.page = val;
        this.tableDateRefresh();
      }

    },
    /*表格-搜索关键词,由于改变才会重搜,所以不用在意 */
    tableChangeSearchKey () {
      this.tableParam.page = 1;
      this.tableDateRefresh();

    },
    /*表格-置亮搜索关键词 */
    tableLightByKey (val) {
      // 不区分大小写  const Reg = new RegExp(searchData, 'i');
      // 全局替换  const Reg = new RegExp(searchData, 'g');
      if (this.tableParam.key) {
        const Reg = new RegExp(this.tableParam.key, 'ig');
        if (val) {
          // 注意 这里推荐使用正则占位符$& 不使用${searchData}  因为当这里使用正则表达式（i）不区分大小写时，如果你的文本是大写，搜索的关键字是小写，匹配结果会替换掉大写的文本
          // const res = val.replace(Reg, `<span style="background-color: yellow;color:#ffffff">${searchData}</span>`);
          const res = val.replace(Reg, `<span style="background-color: #50bfff;color:#ffffff">$&</span>`);
          return res;
        }
      }
      else {
        return val;
      }
    },
    /*表格-数据重置 */
    tableDateRefresh () {
      getTableData(this.tableParam).then((jsonData) => {
        this.tableData = jsonData.data.data;
        this.tableParam.count = jsonData.data.count;
        this.tableParam.page = jsonData.data.page;
        this.tableParam.limit = jsonData.data.limit;
      })
    },
    checkIpAddressSuccess (data) { //JsonArray,数据回传checkIpAddress成功,更新数据
      this.formData.checkIpAddress = data;
    },

    checkMacAddressSuccess (data) { //JsonArray,数据回传checkMacAddress成功,更新数据
      this.formData.checkMacAddress = data;
    },

    checkStorageSuccess (data) { //JsonArray,数据回传checkStorage成功,更新数据
      this.formData.checkStorage = data;
    },

    checkExtDeviceSuccess (data) { //JsonArray,数据回传checkExtDevice成功,更新数据
      this.formData.checkExtDevice = data;
    },
  },
  /*监听Id的变化,如果变化了就重新去取数据 */
  watch: {},
  filters: {},
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    this.formRawData = JSON.parse(JSON.stringify(this.formData)); //深拷贝一份原始数据到formRawData
    this.tableDateRefresh();
    getAllBelong().then((jsonData) => {
      this.allBelong = jsonData.data.data;
    });
    getAllDepart().then((jsonData) => {
      this.allDepart = jsonData.data.data;
    });
    getAllTask().then((jsonData) => {
      this.allTask = jsonData.data.data;
    });
    this.$nextTick(() => {
      this.userInfo = this.$store.getters.getLoginUser;
    })
  }
}
</script>
