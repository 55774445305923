export default {
  state: {
    authorizationToken: "", //登录前验证码的Token,
    token: "", //记录Token,
    loginUser: "",//
    projectHistory: [],
    contractHistory: [],
    supplyHistory: [],
    projectSearchData: {},
    contractSearchData: {},
    supplySearchData: {},
    isCollapse: false, //用于控制菜单展开还是收起,
    collapseIcon: 'el-icon-caret-left',//,
    activeDoc: {
      id: 0,
      directName: ''
    }
  },
  getters: {
    getToken (state) {
      return state.token || localStorage.getItem("token") || "";
    },
    getAuthorizationToken (state) {
      return state.authorizationToken || localStorage.getItem("authorizationToken") || "";
    },
    getLoginUser (state) {
      let userInfo = localStorage.getItem("loginUser");
      console.log(userInfo);
      if (userInfo) {
        state.loginUser = userInfo;
        return JSON.parse(userInfo)
      }
      else if (state.loginUser) {
        return JSON.parse(state.loginUser);
      }
      else {
        return {};
      }
    },
    getProjectOperateData (state) {
      return state.projectHistory || localStorage.getItem("projectHistory") || [];
    },
    getContractOperateData (state) {
      return state.contractHistory || localStorage.getItem("contractHistory") || [];
    },
    getSupplyOperateData (state) {
      return state.supplyHistory || localStorage.getItem("supplyHistory") || [];
    },
    //设定项目搜索关键词
    getProjectSearchData (state) {
      return state.projectSearchData || localStorage.getItem("projectSearchData") || {};
    },
    //设定销售合同操搜索关键词
    getContractSearchData (state) {
      return state.contractSearchData || localStorage.getItem("contractSearchData") || {};
    },
    //设定采购合同搜索关键词
    getSupplySearchData (state) {
      return state.supplySearchData || localStorage.getItem("supplySearchData") || {};
    },

  },
  mutations: {
    // 修改token，并将token存入localStorage
    setToken (state, token) {
      state.token = token;
      localStorage.setItem('token', token);
    },
    setLoginUser (state, loginUser) {

      state.loginUser = JSON.stringify(loginUser);
      localStorage.setItem('loginUser', JSON.stringify(loginUser));
      console.log(state);
      console.log(localStorage);
    },
    //设定项目操作的历史记录
    setProjectOperateData (state, operateData) {
      state.projectHistory = operateData;
      localStorage.setItem('projectHistory', operateData);
    },
    //设定销售合同操作的历史记录
    setContractOperateData (state, operateData) {
      state.contractHistory = operateData;
      localStorage.setItem('contractHistory', operateData);
    },
    //设定采购合同操作的历史记录
    setSupplyOperateData (state, operateData) {
      state.supplyHistory = operateData;
      localStorage.setItem('supplyHistory', operateData);
    },

    //设定项目搜索关键词
    setProjectSearchData (state, operateData) {
      state.projectSearchData = operateData;
      localStorage.setItem('projectSearchData', operateData);
    },
    //设定销售合同操搜索关键词
    setContractSearchData (state, operateData) {
      state.contractSearchData = operateData;
      localStorage.setItem('contractSearchData', operateData);
    },
    //设定采购合同搜索关键词
    setSupplySearchData (state, operateData) {
      state.supplySearchData = operateData;
      localStorage.setItem('supplySearchData', operateData);
    },
    // 修改token，并将token存入localStorage
    setAuthorizationToken (state, authorizationToken) {
      state.authorizationToken = authorizationToken;
      localStorage.setItem('authorizationToken', authorizationToken);
    },
    delToken (state) {
      state.token = "";
      localStorage.removeItem("token");

    },
    delAuthorizationToken (state) {
      state.authorizationToken = "";
      localStorage.removeItem("authorizationToken");
    },
    delLoginUser (state) {
      state.loginUser = "";
      localStorage.removeItem("loginUser");
      console.log(localStorage);
      console.log(state);
    },
    //定义一个修改菜单展开还是收起的方法
    cllapseMenu (state) {
      state.isCollapse = !state.isCollapse;
      if (state.isCollapse) {
        state.collapseIcon = 'el-icon-caret-left'

      }
      else {
        state.collapseIcon = 'el-icon-caret-right'
      }
    },
    setActiveDoc (state, data) {
      state.activeDoc.id = data.id;
      state.activeDoc.directName = data.directName;
    }
  }
}
/**
 * 登录状态的全局token
 */