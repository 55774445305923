import { httpForm, httpJson } from '@/utils/adminRequest'
//定义一个BackUp接口,数据表接口
export const getTableData = () => {
    return httpJson.get('/backUp/list')
}
export const getInfo = (param) => {
    return httpJson.get('/backUp/info', { 'params': param })
}
export const doDelete = (formData) => {
    return httpForm.post('/backUp/delete', formData)
}
export const doDeleteAll = () => {
    return httpForm.post('/backUp/deleteAll')
}
export const doBack = (formData) => {
    return httpForm.post('/backUp/backData', formData)
}



