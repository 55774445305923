import Vue from 'vue';
import App from './App.vue';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import '@/utils/dialogDrag';
import router from './router/allRouter' //必须那边声明 export default后才可以调用
import store from './store' //必须那边声明 export default后才可以调用
import * as echarts from 'echarts';
import { notifyInfo, notifySuccess, notifyWarning, notifyError, downloadFile, countPercentage, numView } from '@/utils/methods'; //引入全局方法
import { sizeFilter, numFilter, nullNumFilter, rateFilter, cmpFilter, saleNameFilter, seasonFilter } from '@/utils/filters'; //引入全局过滤器
/*全局过滤器 */
Vue.filter('sizeFilter', sizeFilter);//引入文件大小过滤
Vue.filter('numFilter', numFilter);//引入数值万数计数
Vue.filter('nullNumFilter', nullNumFilter);//非空数字计数
Vue.filter('rateFilter', rateFilter);//引入税率过滤
Vue.filter('cmpFilter', cmpFilter);//引入公司名称过滤
Vue.filter('saleNameFilter', saleNameFilter);//销售名称过滤
Vue.filter('seasonFilter', seasonFilter);//跟据时间日期得到季节过滤
/*全局过滤器 */

/*全局方法挂载*/
Vue.prototype.notifyInfo = notifyInfo;
Vue.prototype.notifySuccess = notifySuccess;
Vue.prototype.notifyWarning = notifyWarning;
Vue.prototype.notifyError = notifyError;
Vue.prototype.downloadFile = downloadFile;
Vue.prototype.countPercentage = countPercentage;
Vue.prototype.numView = numView;
/*全局方法挂载*/


//import './api/mock'  //调用数据模拟,一般情况下是不需要的
Vue.use(ElementUI);
Vue.prototype.$echarts = echarts //引入图表
/*设置全局Axios */
new Vue({
  router,//挂进router
  store,
  render: h => h(App),
}).$mount('#app')
