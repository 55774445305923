import { httpForm, httpJson } from '@/utils/adminRequest'
//定义一个Storage接口,数据表接口
export const getTableData = (tableParam) => {
    return httpJson.get('/storage/list', { 'params': tableParam })
}
export const inExcel = (formData) => {
    return httpForm.post('/storage/inExcel', formData)
}
export const getInfo = (param) => {
    return httpJson.get('/storage/info', { 'params': param })
}
export const doNew = (formData) => {
    return httpForm.post('/storage/new', formData)
}
export const doModify = (formData) => {
    return httpForm.post('/storage/modify', formData)
}
export const doDelete = (formData) => {
    return httpForm.post('/storage/delete', formData)
}


