import { httpForm, httpJson } from '@/utils/adminRequest'
//定义一个IpPermit接口,数据表接口
export const getTableData = (tableParam) => {
    return httpJson.get('/ipPermit/list', { 'params': tableParam })
}
export const getDocPanelGroup = () => {
    return httpJson.get('/ipPermit/docPanelGroup')
}
export const getInfo = (param) => {
    return httpJson.get('/ipPermit/info', { 'params': param })
}
export const doNew = (formData) => {
    return httpForm.post('/ipPermit/new', formData)
}
export const doModify = (formData) => {
    return httpForm.post('/ipPermit/modify', formData)
}
export const doDelete = (formData) => {
    return httpForm.post('/ipPermit/delete', formData)
}


