import { httpForm, httpJson } from '@/utils/adminRequest'
//定义一个Menus接口,数据表接口
export const findDataBy = (tableParam) => {
    return httpJson.get('/menus/findDataBy', { 'params': tableParam })
}
export const getInfo = (param) => {
    return httpJson.get('/menus/info', { 'params': param })
}
export const doNew = (formData) => {
    return httpForm.post('/menus/new', formData)
}
export const doModify = (formData) => {
    return httpForm.post('/menus/modify', formData)
}
export const doDelete = (formData) => {
    return httpForm.post('/menus/delete', formData)
}
export const doLock = (formData) => {
    return httpForm.post('/menus/lock', formData)
}


