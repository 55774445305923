import { httpJson } from '@/utils/adminRequest'
//定义一个Menus接口,数据表接口
export const getTopMenus = () => {
    return httpJson.get('/topMenus/list')
}

export const getLeftMenus = () => {
    return httpJson.get('/leftCusMenus/list')
}

export const getTopCusMenus = () => {
    return httpJson.get('/topCusMenus/list')
}

export const getTopRightCusMenus = () => {
    return httpJson.get('/topRightCusMenus/list')
}

export const getTopLeftCusMenus = () => {
    return httpJson.get('/topLeftCusMenus/list')
}


