<!--上传文件或图片组件-->
<template>
  <el-input v-model="upFile" class="input-with-select" placeholder="请上传文件" :readonly="this.readOnly" @change="changeValue">
    <template slot="append">
      <el-upload ref="upFileUpload" action="" :http-request="upFileSubmit" :before-upload="upFileSubmitBefore" :on-error="notifyError" :show-file-list="false">
        <i class="el-icon-upload" style="font-size: 25px;color:#409EFF"></i>
      </el-upload>
    </template>
  </el-input>
</template>
<script>
import { postFile } from '@/api/utils/UploadFileOrImg.js'; //数据源,如果没有使用,为了美观请删除
export default {
  props: {
    inputValue: { type: String, require: true }, //绑定的值
    label: { type: String, require: true }, //标题
    allow: { type: String, require: true }, //允许上传的文件
    readOnly: { type: Boolean, require: true }, //是否只读
    childDir: { type: String, require: true }, //创建的目录
  },
  data () {
    return { upFile: this.inputValue };
  },
  methods: {
    /*特殊事件-出错跟踪 */
    notifyWarning (warning) { this.$notify.warning({ title: '警告', message: warning }); },
    notifyError (error) { this.$notify.error({ title: '错误', message: error }); },
    /*-------------------------------------------------单文件(字段名里带File)上传 -----------------------------------------------*/
    /*上传upFile文件类型判断,默认doc,docx,pdf是都可以上传*/
    changeValue (value) {
      const data = { serverPath: value }
      this.$emit('success', data);
    },
    upFileSubmitBefore (file) {
      const fileType = file.name.substring(file.name.lastIndexOf('.'))
      if (this.allow.indexOf(fileType) == -1) {  //允许的文件类型请自己修改,但请注意,是带.的
        this.notifyWarning('上传失败,请上传\n' + this.allow + '文件')
        return false;
      }
      else {
        return true;
      }
    },
    upFileSubmit (param) {
      const upFileData = new FormData()
      upFileData.append('file', param.file);
      upFileData.append('childFile', this.childDir);
      postFile(upFileData).then(response => {
        if (response.data.success) {
          //this.upFile = response.data.data.serverPath;
          this.$emit('success', response.data.data);
          param.onSuccess();  // 上传成功的图片会显示绿色的对勾
        }
        else {
          param.onError(response.data.msg);//会去调用出错的方法
        }
      }).catch(error => {
        param.onError(error); //会去调用出错的解决办法
      })
    },
    /*-------------------------------------------------单文件(字段名里带File)上传 -----------------------------------------------*/
  },
  watch: {
    inputValue: function () {
      this.upFile = this.inputValue;
    }
  },
}
</script>
