import axios from 'axios'
import store from '@/store/index'
import router from '@/router/allRouter'
import domMessage from './message'
import {
  Loading
} from 'element-ui'
const messageOnce = new domMessage(); //只弹一次的窗口
/**
 * httpHtml用来作网页资源的请求
 */
const httpHtml = axios.create({
  baseURL: '/fileUpload',
  timeout: 100000,
  headers: { 'Content-Type': 'text/html' }
});
httpHtml.interceptors.request.use(function (config) {
  return config;
}, function (error) {
  return Promise.reject(error);
});
httpHtml.interceptors.response.use(function (response) { // 添加响应拦截器
  return response;
}, function (error) {
  return Promise.reject(error);
});
/**
 * 用来作Form的请求
 */
const httpForm = axios.create({
  //地址前缀
  baseURL: '/wbkj',
  timeout: 100000,
  headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
});
httpForm.interceptors.request.use(function (config) {
  return insertToken(config);//注入token
}, function (error) {
  return Promise.reject(error);
});
httpForm.interceptors.response.use(function (response) { // 添加响应拦截器
  return doResponse(response);//统一处理响应
}, function (error) {
  doResponseError(error);//统一处理错误
  return Promise.reject(error.response);
});

/**
 * 用来作Jsong的请求
 */
const httpJson = axios.create({
  //地址前缀
  baseURL: '/wbkj',
  timeout: 100000,
  headers: { 'Content-Type': 'application/json' }
});
let loading;
httpJson.interceptors.request.use(function (config) {
  let showLoading = true
  if (config.loading === false) {
    showLoading = false
  }
  if (showLoading && !config.hideloading) {
    loading = Loading.service({
      text: config.loadtext || '加载中...',
      spinner: 'el-icon-loading',
      background: 'rgba(255, 255, 255, 0.2)'
    })
  }
  return insertToken(config);//注入token

}, function (error) {
  return Promise.reject(error);
});
httpJson.interceptors.response.use(function (response) { // 添加响应拦截器
  if (loading) {
    loading.close()
  }
  return doResponse(response);//统一处理响应
}, function (error) {
  loading.close()
  doResponseError(error);//统一处理错误
  return Promise.reject(error);
});
/**
 * 用来作文件包括图片上传及下载的请求
 */
const httpFile = axios.create({
  //地址前缀
  baseURL: '/wbkj',
  timeout: 100000,
  headers: { 'Content-Type': 'multipart/form-data' }
});
httpFile.interceptors.request.use(function (config) {
  return insertToken(config);//注入token
}, function (error) {
  // 对请求错误做些什么
  return Promise.reject(error);
});

// 添加响应拦截器
httpFile.interceptors.response.use(function (response) {
  return doResponse(response);//统一处理响应
}, function (error) {
  doResponseError(error);//统一处理错误
  //return Promise.reject(error);
});

//------------------------------------------通用的方法设定-------------------------------------------------------------------------
function insertToken (config) {
  const token = store.getters.getToken;

  if (token) { // 已经登录成功，统一添加token,如果没有那就直接请求
    config.headers.wbkjToken = token;
  }
  return config;
}
/**
 * 统一处理返回
 * @param {} response 
 * @returns 
 */

function doResponse (response) {
  if (response.status === 200) {
    return Promise.resolve(response);
  } else {
    return Promise.reject(response);
  }
}
/**
 * 统一处理错误代码
 * @param {} response 
 * @returns 
 */
function doResponseError (error) {
  switch (error.response.status) {
    // 401: 未登录
    // 未登录则跳转登录页面，并携带当前页面的路径
    // 在登录成功后返回当前页面，这一步需要在登录页操作。                
    case 401:
      messageOnce.error({
        message: '帐号未登录,请登录',
        type: 'error'
      })
      router.replace({
        path: '/login',
        query: {
          redirect: router.currentRoute.fullPath
        }
      });
      break;
    // 403 token过期
    // 登录过期对用户进行提示
    // 清除本地token和清空vuex中token对象
    // 跳转登录页面                
    case 403:
      messageOnce.error({
        message: error.response.data.msg,
        type: 'error'
      });
      // 清除token
      localStorage.removeItem('token');
      store.commit('loginSuccess', null);
      // 跳转登录页面，并将要浏览的页面fullPath传过去，登录成功后跳转需要访问的页面 
      setTimeout(() => {
        router.replace({
          path: '/login',
          query: {
            redirect: router.currentRoute.fullPath
          }
        });
      }, 1000);
      break;
    case 405:
      messageOnce.error({
        message: error.response.data.msg,
        type: 'error'
      });
      // 清除token
      localStorage.removeItem('token');
      store.commit('loginSuccess', null);
      // 跳转登录页面，并将要浏览的页面fullPath传过去，登录成功后跳转需要访问的页面 
      setTimeout(() => {
        router.replace({
          path: '/login',
          query: {
            redirect: router.currentRoute.fullPath
          }
        });
      }, 1000);
      break;

    // 404请求不存在
    case 404:
      messageOnce.error({
        message: '网络请求不存在,请检查网络',
        type: 'error'
      });
      break;
    // 其他错误，直接抛出错误提示
    default:
      messageOnce.error({
        message: error.response.data.msg,
        type: 'error'
      });
      setTimeout(() => {
        router.replace({
          path: '/login',
          query: {
            redirect: router.currentRoute.fullPath
          }
        });
      }, 1000);
  }
}
export { httpForm, httpJson, httpFile, httpHtml }