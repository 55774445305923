import { httpForm, httpJson } from '@/utils/adminRequest'
//定义一个Role接口,数据表接口
export const getTableData = (tableParam) => {
    return httpJson.get('/role/list', { 'params': tableParam })
}
export const getInfo = (param) => {
    return httpJson.get('/role/info', { 'params': param })
}
export const getAllDocPanel = (param) => {
    return httpJson.get('/role/allDocPanel', { 'params': param })
}
export const getAllCusPanel = (param) => {
    return httpJson.get('/role/allCusPanel', { 'params': param })
}
export const doNew = (formData) => {
    return httpForm.post('/role/new', formData)
}
export const doModify = (formData) => {
    return httpForm.post('/role/modify', formData)
}
export const doDelete = (formData) => {
    return httpForm.post('/role/delete', formData)
}


