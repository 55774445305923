<template>
  <div>

    <el-tag :key="i" v-for="(val,key,i) in this.testJsonExt" closable :disable-transitions=" false" @click="testJsonExtEdit(key,val)"
      @close="testJsonExtDelete(key)" style="margin-right: 10px;">
      {{key}}:{{val}}
    </el-tag>
    <el-button size="small" type="success" @click="testJsonExtNew()">+</el-button>
    <el-dialog v-dialogDrag :title="testJsonExtDialogParam.title" :visible.sync="testJsonExtDialogParam.visible" :width="testJsonExtDialogParam.width"
      :close-on-click-modal='false' append-to-body>
      <el-form :model="testJsonExtData" label-width="100px" size="small" ref="testJsonExtForm" :rules="testJsonExtDataRules">
        <el-form-item label="键名(key)" prop="key">
          <el-input v-model="testJsonExtData.key" placeholder="请输入英文键名(key),重复的键名会覆盖"></el-input>
        </el-form-item>
        <el-form-item label="值(value)" prop="value">
          <el-input v-model="testJsonExtData.value" placeholder="请输入值(value)"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="testJsonExtDialogParam.visible = false">取 消</el-button>
        <el-button @click="testJsonExtPush()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { required } from "@/utils/formRules" //校验规则,如有特殊要求请自己去修改填写,请删除不使用的,但不删除也不影响
export default {
  props: { jsonInfo: { type: Object, required: true } },
  data () {
    return {
      testJsonExt: this.jsonInfo,
      /*-------------------------------------------------json testJsonExt 参数-----------------------------------------------*/
      testJsonExtDialogParam: {
        visible: false,
        title: '新建',
        width: '50%',
        top: '15vh',
      },
      /*--json 字段参数--*/
      testJsonExtRawData: {
        key: '',
        value: '',
      },
      testJsonExtData: {
        key: '',
        value: '',
      },
      testJsonExtDataRules:
      {
        key: [required],
        value: [required],
      },
      exitKey: '',//修改时原来的key
    };
  },
  methods: {
    /*-------------------------------------------------json testJsonExt 方法-----------------------------------------------*/
    testJsonExtNew () {
      this.testJsonExtData = JSON.parse(JSON.stringify(this.testJsonExtRawData))//重置
      this.testJsonExtDialogParam.title = "新建";
      this.testJsonExtDialogParam.visible = true;
    },
    testJsonExtPush () {
      this.$refs.testJsonExtForm.validate((valid) => {
        if (valid) {
          if (!this.exitKey) //如果存在exitKey,表示是修改,原来的key可能会被改了,这时候需要判断是否改过exitKey
          {
            this.$set(this.testJsonExt, this.testJsonExtData.key, this.testJsonExtData.value);
          }
          else if (this.exitKey == this.testJsonExtData.key) {
            this.$set(this.testJsonExt, this.testJsonExtData.key, this.testJsonExtData.value);
            this.exitKey = '';
          }
          else {
            this.$delete(this.testJsonExt, this.exitKey);
            this.$set(this.testJsonExt, this.testJsonExtData.key, this.testJsonExtData.value);
            this.exitKey = '';
          }
          this.testJsonExtDialogParam.visible = false;//不显示
          this.$emit('success', this.testJsonExt);
        }
      });
    },
    testJsonExtDelete (key) {
      this.$delete(this.testJsonExt, key);
      this.$emit('success', this.testJsonExt);
    },
    testJsonExtEdit (key, val) {
      this.exitKey = JSON.parse(JSON.stringify(key));
      this.testJsonExtData.key = key;
      this.testJsonExtData.value = val;
      this.testJsonExtDialogParam.title = "修改";
      this.testJsonExtDialogParam.visible = true;
    },
  },
  watch: {
    jsonInfo: function () {
      this.testJsonExt = this.jsonInfo;
    }
  },
  mounted () {
  },
}
</script>
