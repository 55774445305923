import { httpForm, httpJson } from '@/utils/adminRequest'
//定义一个Host接口,数据表接口
export const getTableData = (tableParam) => {
    return httpJson.get('/host/list', { 'params': tableParam })
}
export const getInfo = (param) => {
    return httpJson.get('/host/info', { 'params': param })
}
export const inExcel = (formData) => {
    return httpForm.post('/host/inExcel', formData)
}
export const doNew = (formData) => {
    return httpForm.post('/host/new', formData)
}
export const doModify = (formData) => {
    return httpForm.post('/host/modify', formData)
}
export const doDelete = (formData) => {
    return httpForm.post('/host/delete', formData)
}
export const getSecretLevel = () => {
    return httpJson.get('/secretLevel')
}
export const getLinkState = () => {
    return httpJson.get('/linkState')
}
export const getAllDepart = () => {
    return httpJson.get('/allDepart')
}
export const getAllBelong = () => {
    return httpJson.get('/allBelong')
}