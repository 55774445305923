<template>
  <div>
    <el-row>
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/admin' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>{{ this.direcParam.directName }}</el-breadcrumb-item>
      </el-breadcrumb>
    </el-row>
    <el-row>
      <el-col :span="18">
        <el-button-group>
          <el-button @click="rightNowBack" type="primary">立即备份</el-button>
          <el-button @click="formDataDeleteAll" type="danger">清空备份数据</el-button>
        </el-button-group>
      </el-col>
    </el-row>

    <el-table ref="backUpTableRef" :border="true" height="666" :cell-style="{height:'44px',padding:0,margin:0}" :data="tableData" tooltip-effect="dark"
      style="width: 100%" :highlight-current-row="true">
      <el-table-column header-align="center" type="index" width="40"></el-table-column>
      <el-table-column header-align="center" prop="fileName" label="文件名称">

      </el-table-column>
      <el-table-column header-align="center" prop="size" label="文件大小">
        <template slot-scope="scope">
          {{ scope.row.size|numFilter }}
        </template>
      </el-table-column>
      <el-table-column label="操作" width="200">
        <template slot-scope="scope">
          <el-button @click="formDataDownload(scope.row)" type="text" size="small">下载</el-button>
          <el-button @click="formDataDelete(scope.row)" type="text" size="small">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog v-dialogDrag class="customFileListCss" title="立即备份" :visible.sync="backUpParam.visible" width="50%" top="5vh" :close-on-click-modal='false'>
      <el-form ref="formRef" label-width="90px" size="small">
        <el-form-item label="密 码">
          <el-input v-model="backUpParam.loginPass" prefix-icon="el-icon-lock" placeholder="为了确保是您本人操作,请输入登录帐号的密码" type="password"></el-input>
        </el-form-item>
        <el-form-item label="注意事项">
          <el-row>
            <el-col :span="12">1.系统每周周一会自动进行备份</el-col>
            <el-col :span="12">2.备份占用系统资源,请不要在多人使用的高峰期备份</el-col>
            <el-col :span="12">3.备份的时间因数据大小原因,时间有长有短</el-col>
            <el-col :span="12">4.确认后,系统无法立刻返回数据表,请等待</el-col>
            <el-col :span="12">5.您可以估计时长,刷新即可看到最新结果</el-col>
            <el-col :span="12">6.备份过程无法中断,每日多次点击只会得到同名的一个文件</el-col>
            <el-col :span="12">7.请妥善保存好您的备份,养成良好的备份习惯</el-col>
            <el-col :span="12">8.备份恢复,请参考用户手册或与系统开发方取得联系(此项收费)</el-col>
            <el-col :span="12">9.备份到第三方对象存储或网盘,请参考开发手册</el-col>
            <el-col :span="12">10.为确保系统有足够空间运行,请及时清除备份文件</el-col>
            <el-col :span="24">11.备份数据使用证书秘钥进行加密,非本机本系统无法使用</el-col>
          </el-row>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="backUpParam.visible = false">取 消</el-button>
        <el-button type="primary" @click="formDataBack">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<style lang="less" scoped>
@import "@/assets/css/TableCus.css";
</style>
<script>
import saveAs from 'file-saver'
import { Loading } from 'element-ui'
import { getTableData, doDelete, doDeleteAll, doBack } from '@/api/wbkj/BackUp'; //数据源,如果没有上传文件的部分,请删除,不删除也不影响

export default {
  data () {
    return {
      /*导航栏状态 */
      direcParam: {
        'directName': '备份管理'
      },
      downToken: '',
      /*表格数据 */
      tableData: [],
      backUpParam:
      {
        loginPass: '',
        visible: false,
      },
      loading: {}
    }
  },
  methods: {
    rightNowBack () {
      this.backUpParam.loginPass = '';
      this.backUpParam.visible = true
    },
    formDataDownload (row) {
      saveAs('/wbkj/backUp/download?fileName=' + row.fileName + '&downToken=' + this.downToken, row.fileName);
    },
    /*表单(form)-删除 */
    formDataDelete (row) {
      this.$confirm('删除不可恢复,是否确认删除' + row.fileName + '?', '提示', {
        confirmButtonText: '确 定',
        cancelButtonText: '取 消',
        type: 'warning'
      }).then(() => {
        const postData = JSON.parse(JSON.stringify(row)); //深拷贝一份原始数据到contractClauseData,就相当于重置表单了
        doDelete(postData).then(response => {
          if (response.data.success) {
            this.notifySuccess(response.data.msg);
            this.tableDateRefresh();
          }
          else {
            this.notifyWarning(response.data.msg);
          }
        })
      }).catch(() => {
      });
    },
    formDataDeleteAll () {
      this.$confirm('清空数据不可恢复,是否确认?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取 消',
        type: 'warning'
      }).then(() => {

        doDeleteAll().then(response => {
          if (response.data.success) {
            this.notifySuccess(response.data.msg);
            this.tableDateRefresh();
          }
          else {
            this.notifyWarning(response.data.msg);
          }
        })
      }).catch(() => {
      });
    },
    formDataBack () {
      this.loading = Loading.service({
        text: '正在生成备份,请等待....,如果系统失去响应,您可以喝杯咖啡,再来刷新',
        spinner: 'el-icon-loading',
        background: 'rgba(255, 255, 255, 0.2)'
      });
      this.backUpParam.visible = false;
      doBack({ loginPass: this.backUpParam.loginPass }).then(response => {
        if (response.data.success) {
          this.loading.close();
          this.backUpParam.visible = false;
          this.notifySuccess(response.data.msg);
          this.tableDateRefresh();
        }
        else {
          this.loading.close();
          this.backUpParam.visible = true;
          this.notifyWarning(response.data.msg);
        }
      })
    },

    /*表格-数据重置 */
    tableDateRefresh () {
      getTableData().then((jsonData) => {
        this.tableData = jsonData.data.data;
      })
    },
  },
  /*监听Id的变化,如果变化了就重新去取数据 */
  watch: {},
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    this.tableDateRefresh();
    this.downToken = this.$store.getters.getToken;
  }
}
</script>
