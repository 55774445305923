import { httpForm, httpJson } from '@/utils/adminRequest'
//定义一个ExtDevice接口,数据表接口
export const getTableData = (tableParam) => {
    return httpJson.get('/extDevice/list', { 'params': tableParam })
}

export const inExcel = (formData) => {
    return httpForm.post('/extDevice/inExcel', formData)
}
export const getInfo = (param) => {
    return httpJson.get('/extDevice/info', { 'params': param })
}
export const doNew = (formData) => {
    return httpForm.post('/extDevice/new', formData)
}
export const doModify = (formData) => {
    return httpForm.post('/extDevice/modify', formData)
}
export const doDelete = (formData) => {
    return httpForm.post('/extDevice/delete', formData)
}


