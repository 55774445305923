<!--  -->
<template>
  <div style="background-color: rgb(84, 92, 100);">
    <el-menu default-active="home" class="el-menu-vertical-demo" :collapse="isCollapse" background-color="#545c64" text-color="#fff" active-text-color="#ffd04b"
      :default-openeds=defaultOpen router>
      <h3 style="display:flex"><img :src='`/icon/logo2.png`' width="50px" height="50px" />{{isCollapse?'':settingData.sysName}}</h3>
      <el-menu-item index="home">
        <i class="el-icon-s-home"></i>
        <span slot="title">首页</span>
      </el-menu-item>
      <el-submenu v-for="node in menuData" :key="node.id" :index="'left_'+node.id" :collapse="true"
        :style="node.bodyItems.length>0?'display:block':'display:none'">
        <template slot="title">
          <i :class="node.imageUrl"></i>
          <span slot="title">{{node.panelName}}</span>
        </template>
        <el-menu-item v-for="cusPanel in node.bodyItems" :key="cusPanel.id" :index="cusPanel.panelEnName">{{cusPanel.panelName}}</el-menu-item>
      </el-submenu>
    </el-menu>
  </div>
</template>



<style lang="less" scoped>
.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 200px;
  min-width: 200px;
  min-height: 400px;
}
.el-menu {
  height: 100vh;
  border: 0;
  width: auto;
  h3 {
    padding-left: 10px;
    color: #fff;
    text-align: center;
    line-height: 48px;
    font-size: 16px;
    font-weight: 400;
  }
  li {
    ul {
      height: 300%;
    }
  }
}
.el-menu-item {
  height: 36px;
  line-height: 36px;
}
</style>

<script>
import { getLeftMenus } from '@/api/wbkj/MainMenu'; //数据源,如果没有使用,为了美观请删除
import { getSetting } from '@/api/wbkj/Setting'; //系统设定信息
export default {
  data () {
    return {
      settingData: {},
      menuData: [],
      defaultOpen: []
    };
  },
  methods: {
    initSetting () {
      getSetting().then(response => {
        if (response.data.success) {
          this.settingData = response.data.data;
        }
        else {
          this.notifyWarning(response.data.msg);
        }
      })
    },
    clickMenuItem (bodyItem) {
      if (!(this.$route.path == "/docInfo" && this.$route.query.id == bodyItem.id)) {
        this.$store.commit('setActiveDoc', { id: bodyItem.id, directName: bodyItem.viewName }); //存到Store里,store已经存在cookie里了,万一刷新页面不丢
        this.$router.push({
          name: 'docInfo',
          //query: { id: bodyItem.id },
          params: {
            id: bodyItem.id,
            directName: bodyItem.viewName
          }
        });
      }

    }
  },
  mounted () {
    this.initSetting();
    //取得全部角色
    getLeftMenus().then(response => {
      if (response.data.success) {
        this.menuData = response.data.data;
        for (let i = 0; i < this.menuData.length; i++) {
          this.defaultOpen.push('left_' + this.menuData[i].id);
        }
      }
      else {

        this.notifyWarning(response.data.msg);
      }
    })
  },
  computed: {
    isCollapse () {
      return this.$store.state.adminState.isCollapse;
    }
  }
}
</script>