<!--  -->
<template>
  <el-menu mode="horizontal" background-color="#545c64" text-color="#fff" active-text-color="#ffd04b" router>
    <el-menu-item @click="hidenShowLeftMenu" class="menuItemByShowOrHiden"><i :class="`${iconClass}`"></i>
    </el-menu-item>
    <el-submenu v-for="item in menuData" :key="item.id" :index="item.viewName" :style="item.bodyItems.length>0?'display:block':'display:none'">
      <template slot="title">
        <i class="el-icon-folder"></i>
        <span slot="title">{{item.viewName}}</span>
      </template>
      <el-menu-item v-for="bodyItem in item.bodyItems" :key="`${item.id}-${bodyItem.id}`" :index="`${item.id}-${bodyItem.id}`" @click="clickMenuItem(bodyItem)">
        {{bodyItem.viewName}}
      </el-menu-item>
    </el-submenu>
    <el-submenu v-for="node in topLeftMenu" :key="'top_left_'+node.id" :index="'top_left_'+node.id"
      :style="node.bodyItems.length>0?'display:block':'display:none'">
      <template slot="title">
        <i :class="node.imageUrl"></i>
        <span slot="title">{{node.panelName}}</span>
      </template>
      <el-menu-item v-for="cusPanel in node.bodyItems" :key="cusPanel.id" :index="cusPanel.panelEnName">{{cusPanel.panelName}}</el-menu-item>
    </el-submenu>
    <el-submenu index="userInfo" style="float:right">
      <template slot="title"><i class="el-icon-s-custom" style="color:#409eff">&nbsp;</i>{{this.$store.getters.getLoginUser.loginName}}</template>
      <el-menu-item index="userInfo">用户中心</el-menu-item>
      <el-menu-item @click="loginOut">退出系统</el-menu-item>
    </el-submenu>
    <el-submenu v-for="node in topRightMenu" :key="'top_right_'+node.id" :index="'top_right_'+node.id" style="float:right"
      :style="node.bodyItems.length>0?'display:block':'display:none'">
      <template slot="title">
        <i :class="node.imageUrl"></i>
      </template>
      <el-menu-item v-for="cusPanel in node.bodyItems" :key="cusPanel.id" :index="cusPanel.panelEnName">{{cusPanel.panelName}}</el-menu-item>
    </el-submenu>
  </el-menu>
</template>
<style lang="less" scoped>
.menuItemByShowOrHiden {
  padding: 0px;
}
</style>
<script>
import { getTopLeftCusMenus, getTopMenus, getTopRightCusMenus } from '@/api/wbkj/MainMenu'; //数据源,如果没有使用,为了美观请删除

export default {
  data () {
    return {
      
      iconHiden: false,
      iconClass: "el-icon-caret-left",
      menuData: [],
      topLeftMenu: [],
      topRightMenu: []
    };
  },
  //方法集合
  methods: {
    
    hidenShowLeftMenu () {
      this.$data.iconStatus = !this.$data.iconStatus;
      if (this.$data.iconStatus) {
        this.$data.iconClass = "el-icon-caret-right";
      }
      else {
        this.$data.iconClass = "el-icon-caret-left";
      }
      this.$store.commit('cllapseMenu');
    },
    clickMenuItem (bodyItem) {
      if (!(this.$route.path == "/docInfo" && this.$route.query.id == bodyItem.id)) {
        this.$store.commit('setActiveDoc', { id: bodyItem.id, directName: bodyItem.viewName }); //存到Store里,store已经存在cookie里了,万一刷新页面不丢
        this.$router.push({
          name: 'docInfo',
          //query: { id: bodyItem.id },
          params: {
            id: bodyItem.id,
            directName: bodyItem.viewName
          }
        });
      }

    },
    loginOut () {
      this.$store.commit("delToken");
      this.$store.commit("delLoginUser");
      sessionStorage.clear()
      this.$router.push('/login')
      this.$router.replace({ path: '/login' });
      location.reload();
    }
  },
  mounted () {
    
    getTopMenus().then(response => {
      if (response.data.success) {
        this.menuData = response.data.data;
      }
      else {
        this.notifyWarning(response.data.msg);
      }
    });
    getTopLeftCusMenus().then(response => {
      if (response.data.success) {
        this.topLeftMenu = response.data.data;
      }
      else {

        this.notifyWarning(response.data.msg);
      }
    });
    getTopRightCusMenus().then(response => {
      if (response.data.success) {
        this.topRightMenu = response.data.data;
      }
      else {

        this.notifyWarning(response.data.msg);
      }
    })
  }
}
</script>