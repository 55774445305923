/*特殊事件-出错跟踪 */
export function notifyInfo (info) { this.$notify.info({ title: '提示', message: info }); }
export function notifySuccess (success) { this.$notify.success({ title: '成功', message: success }); }
export function notifyWarning (warning) { this.$notify.warning({ title: '警告', message: warning }); }
export function notifyError (error) { this.$notify.error({ title: '错误', message: error }); }

/*特殊事件-下载文件 */
export function downloadFile (downUrl, downName) {
  let a = document.createElement('a')
  a.href = downUrl + '?downToken=' + this.$store.getters.getToken;
  a.download = downName;
  a.target = '_blank'//在新窗口打开
  a.click();
}
/*计算百分比 */
export function countPercentage (ele, ments, trueValue) {
  if (ments && ele) {
    let value = parseFloat((ele * 100 / ments).toFixed(2) - 0);
    if (value > 100) {
      if (trueValue) {
        return value;
      }
      else {
        return 100;
      }

    }
    else {
      return value;
    }
  }
  else {
    return 0;
  }
}
/*计算金额的大小 Filter里也同时存在numFilter,目的是为了两都共存不用改代码 */
export function numView (value) {
  if (value) {
    if (Math.abs(parseFloat(value / 1000)).toFixed(2) > 5) {
      return parseFloat(value / 10000).toFixed(2) + '万'
    }
    else {
      return parseFloat(value).toFixed(2);
    }
  }
  else {
    return "0.00";
  }
}