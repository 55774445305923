import { httpForm, httpJson } from '@/utils/adminRequest'
//定义一个CheckTask接口,数据表接口
export const getTableData = (tableParam) => {
    return httpJson.get('/checkTask/list', { 'params': tableParam })
}
export const getInfo = (param) => {
    return httpJson.get('/checkTask/info', { 'params': param })
}
export const doNew = (formData) => {
    return httpForm.post('/checkTask/new', formData)
}
export const doModify = (formData) => {
    return httpForm.post('/checkTask/modify', formData)
}
export const doDelete = (formData) => {
    return httpForm.post('/checkTask/delete', formData)
}
export const getAllTask = () => {
    return httpJson.get('/allTask')
}


