import Vue from 'vue'
import VueRouter from 'vue-router'
import AdminMain from '@/views/AdminMain.vue'
import HomeIndex from '@/views/wbkj/HomeIndex.vue'
import DocInfo from '@/views/wbkj/DocInfo.vue'
import Menus from '@/views/wbkj/MenusSet.vue'
import Links from '@/views/wbkj/LinksSet.vue'
import Images from '@/views/wbkj/ImagesSet.vue'
import UserLogin from '@/views/UserLogin.vue'
import DocPanel from '@/views/wbkj/DocPanelSet.vue'
import CusPanel from '@/views/wbkj/CusPanelSet.vue'
import Role from '@/views/wbkj/RoleSet.vue'
import IpPermit from '@/views/wbkj/IpPermitSet.vue'
import AdminSet from '@/views/wbkj/AdminSet.vue' //后台帐号管理
import Users from '@/views/wbkj/UsersSet.vue' //留言用户管理
import UserInfo from '@/views/wbkj/UserInfo.vue' //用户信息
import SettingInfo from '@/views/wbkj/SettingInfo.vue'//网站配置项
import BackUp from '@/views/wbkj/BackUpSet.vue'//备份管理
import TestCreate from '@/views/wbkj/TestCreateSet.vue'
import ExtDevice from '@/views/wbkj/ExtDeviceSet.vue'
import Storage from '@/views/wbkj/StorageSet.vue'
import Host from '@/views/wbkj/HostSet.vue'
import CheckTask from '@/views/wbkj/CheckTaskSet.vue'
import CheckHistory from '@/views/wbkj/CheckHistorySet.vue'
import Software from '@/views/wbkj/SoftwareSet.vue'
import StartList from '@/views/wbkj/StartListSet.vue'

Vue.use(VueRouter)
//2.将路由组用引入到路由中去,说白了就是路径和容器对应
const routes = [
  {
    path: '/', redirect: '/login',  //默认项
  },
  {
    path: '/login', name: 'login', component: UserLogin, //登录页
  },
  {
    path: '/admin',
    component: AdminMain, //主容器
    redirect: '/admin/home',
    children: [
      { path: '/admin/home', name: 'home', component: HomeIndex },//首页
      { path: '/admin/role', name: 'role', component: Role }, //角色管理
      { path: '/admin/docInfo', name: 'docInfo', component: DocInfo },
      { path: '/admin/menus', name: 'menus', component: Menus },
      { path: '/admin/links', name: 'links', component: Links },
      { path: '/admin/images', name: 'images', component: Images },
      { path: '/admin/docPanel', name: 'docPanel', component: DocPanel },
      { path: '/admin/cusPanel', name: 'cusPanel', component: CusPanel },
      { path: '/admin/ipPermit', name: 'ipPermit', component: IpPermit },
      { path: '/admin/adminSet', name: 'adminSet', component: AdminSet },
      { path: '/admin/user', name: 'user', component: Users },
      { path: '/admin/settingInfo', name: 'settingInfo', component: SettingInfo },
      { path: '/admin/userInfo', name: 'userInfo', component: UserInfo },
      { path: '/admin/backUp', name: 'backUp', component: BackUp }, //备份
      { path: '/admin/testCreate', name: 'testCreate', component: TestCreate }, // 测试
      { path: '/admin/host', name: 'host', component: Host }, // 测试
      { path: '/admin/extDevice', name: 'extDevice', component: ExtDevice }, // 测试
      { path: '/admin/storage', name: 'storage', component: Storage }, // 测试
      { path: '/admin/softWare', name: 'software', component: Software }, // 软件清单
      { path: '/admin/startList', name: 'startList', component: StartList }, // 软件清单
      { path: '/admin/checkTask', name: 'checkTask', component: CheckTask }, // 检测任务
      { path: '/admin/checkHistory', name: 'checkHistory', component: CheckHistory }, // 检测任务
    ]
  }
]
// 3. 创建 router 实例，然后传 `routes` 配置,就是将router放到vueRooter中去,
// 你还可以传别的配置参数, 不过先这么简单着吧。
const router = new VueRouter({
  routes // (缩写) 相当于 routes: routes
})
// 4.把这个暴露出来,其它js才可以去使用它
export default router