import { httpForm, httpJson } from '@/utils/adminRequest'
//定义一个Admin接口,数据表接口
export const getTableData = (tableParam) => {
    return httpJson.get('/admin/list', { 'params': tableParam })
}
export const getAllRole = () => {
    return httpJson.get('/admin/allRole')
}
export const getInfo = (param) => {
    return httpJson.get('/admin/info', { 'params': param })
}

export const doNew = (formData) => {
    return httpForm.post('/admin/new', formData)
}
export const doModify = (formData) => {
    return httpForm.post('/admin/modify', formData)
}
export const doDelete = (formData) => {
    return httpForm.post('/admin/delete', formData)
}


