import { render, staticRenderFns } from "./CheckTaskSet.vue?vue&type=template&id=536a9c54&scoped=true&"
import script from "./CheckTaskSet.vue?vue&type=script&lang=js&"
export * from "./CheckTaskSet.vue?vue&type=script&lang=js&"
import style0 from "./CheckTaskSet.vue?vue&type=style&index=0&id=536a9c54&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "536a9c54",
  null
  
)

export default component.exports