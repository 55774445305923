import { httpJson, httpFile } from '@/utils/adminRequest'
//定义一个Cer取得接口,数据表接口
export const getCer = () => {
    return httpJson.get('/cert/info')
}
export const getSys = () => {
    return httpJson.get('/sys/properties')
}
export const getDiskInfo = () => {
    return httpJson.get('/sys/diskInfo')
}
export const postCerFile = (fileData) => {
    return httpFile.post('/upload/cerFile', fileData)
}
